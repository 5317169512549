import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";
import { FetchPost } from "../../helper/helpers";

class ExerciseRoutine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rosterList: [],
      isVisibleLoader: true,
      isOpen: false,
      isOpen1: false,
      modalData: "",
      modalData1: "",
      memberList: [],
      selectedOptions: [],
      selectedOptions1: [],
      activeRosterId: "",
      memberListForAddMembers: [],
    };
  }
  toggleModal = (item) => {
    this.setState((prevState) => ({
      modalData: item,
      isOpen: !prevState.isOpen,
    }));
  };
  toggleModal1 = (item1) => {
    this.setState(
      (prevState1) => ({
        activeRosterId: item1.roster_id,
        isOpen1: !prevState1.isOpen1,
      }),
      () => {
        this.getMemberForAddMember(item1.roster_id);
      }
    );
  };
  handleSelectChange = (selectedOptions) => {
    this.setState({ selectedOptions });
  };
  handleSelectChange1 = (selectedOptions1) => {
    //console.log(selectedOptions1);
    this.setState({ selectedOptions1 });
  };
  componentDidMount() {
    this.getAllRoster();
    this.getMemberList();
  }
  getMemberList = () => {
    let postData = {};
    FetchPost("list-member", postData).then((result) => {
      console.log("member list response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          memberList: result.data,
        });
      }
    });
  };
  getMemberForAddMember = (rosterid) => {
    let postData = { roster_id: rosterid };
    FetchPost("member-list-for-add-member-to-roster", postData).then(
      (result) => {
        console.log("member list response data : ", JSON.stringify(result));
        this.setState({ isVisibleLoader: false });
        if (result.status === 1) {
          this.setState({
            memberListForAddMembers: result.data,
          });
        }
      }
    );
  };
  getAllRoster = () => {
    let postData = {};
    console.log("roster post data is", postData);
    FetchPost("list-roster", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          rosterList: result.data,
        });
      }
    });
  };
  updateState = (stateVar, StateVal) => {
    this.setState({
      [stateVar]: StateVal,
    });
  };
  handleExcercisetypeChange = (event) => {
    this.setState({ member_id: event.target.value });
  };
  saveMemberList = () => {
    let postData = {
      memberdata: this.state.selectedOptions1,
      roster_id: this.state.activeRosterId,
    };
    console.log("roster post data is", postData);
    FetchPost("save-rostermember", postData).then((result) => {
      console.log("member save response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false, isOpen1: false });
      if (result.status === 1) {
        this.getAllRoster();
      }
    });
  };
  render() {
    const {
      rosterList,
      isVisibleLoader,
      isOpen,
      isOpen1,
      memberList,
      memberListForAddMembers,
    } = this.state;

    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Schedule</h2>
            </div>
            <div className="tab-content" id="myTabContent">
              <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="toolbar"></div>
                        <table id="datatable" className="table table-striped">
                          <thead>
                            <tr>
                              <th>Id</th>
                              <th>Batch Name</th>
                              <th>Exercise Type</th>
                              <th>Week</th>
                              <th>Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rosterList.map((rosterField, Index) => {
                              return (
                                <tr>
                                  <td>{Index + 1}</td>
                                  <td>{rosterField.batchname}</td>
                                  <td>{rosterField.description}</td>
                                  <td>{rosterField.week}</td>
                                  <td>
                                    {rosterField.fromtime} -{" "}
                                    {rosterField.totime}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default ExerciseRoutine;
