import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BASE_URL } from "../../helper/vars";
import Footer from "../layout/Footer";
import { useSignIn } from "react-auth-kit";
import { ROOT_URL } from "../../helper/vars";

function Login() {
  const signIn = useSignIn();
  const [email, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [phoneErr, setphoneErr] = useState("");
  const [passwordErr, setpasswordErr] = useState("");
  const [authErr, setauthErr] = useState("");
  //const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // const loginID = localStorage.getItem("loginID");
    // if (loginID != "" || loginID != null) {
    //   navigate("/dashboard");
    // }
  }, [0]);

  const submituserLoginForm = (e) => {
    e.preventDefault();

    if (validateForm()) {
      let result = fetch(BASE_URL + "login", {
        method: "post",
        body: JSON.stringify({ email: email, password: password }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.user != undefined) {
            // localStorage.setItem("loginID", json.user.id);
            // localStorage.setItem("userRole", json.user.role);
            if (
              signIn({
                token: json.token,
                expiresIn: 30000,
                tokenType: "Bearer",
                authState: json.user,
                //refreshToken: res.data.refreshToken, // Only if you are using refreshToken feature
                //refreshTokenExpireIn: res.data.refreshTokenExpireIn, // Only if you are using refreshToken feature
              })
            ) {
              console.log(json.user);
              window.location = ROOT_URL + "dashboard";
            } else {
              console.log("Auth not done");
            }
            //navigate("/dashboard");
          } else {
            setPhone("");
            setPassword("");
            setphoneErr("");
            setpasswordErr("");
            setauthErr(json.message);
          }
        });
    }
  };
  const validateForm = () => {
    setauthErr("");
    if (email == "") {
      setphoneErr("*Please enter Email.");
      return false;
    } else if (password == "") {
      setphoneErr("");
      setpasswordErr("*Please enter your password.");
      return false;
    } else {
      setpasswordErr("");
      return true;
    }
  };

  return (
    <div className="wrapper wrapper-full-page ">
      <div className="full-page login-page ">
        <div className="content">
          <div className="container">
            <div className="col-lg-4 col-md-6 ml-auto mr-auto">
              <form className="form" onSubmit={submituserLoginForm}>
                <div className="card card-login card-white">
                  <div className="card-header">
                    <img src="../../assets/img/card-primary.png" />
                    <h1 className="card-title">Log in</h1>
                  </div>
                  <div className="card-body">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="tim-icons icon-pencil"></i>
                        </div>
                      </div>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Email"
                      />
                    </div>
                    <label className="error-red" htmlFor="email">
                      {phoneErr}
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="tim-icons icon-lock-circle"></i>
                        </div>
                      </div>
                      <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <label className="error-red" htmlFor="password">
                      {passwordErr}
                    </label>
                  </div>
                  <div className="card-footer">
                    <label className="error-red">{}</label>
                    <button
                      type="submit"
                      className="btn btn-success btn-lg btn-block mb-3"
                    >
                      Let's go
                    </button>
                    {/* <div className="pull-left">
                                <h6>
                                <a href="javascript:void(0)" className="link footer-link">Create Account</a>
                                </h6>
                            </div>
                            <div className="pull-right">
                                <h6>
                                <a href="javascript:void(0)" className="link footer-link">Need Help?</a>
                                </h6>
                            </div> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Login;
