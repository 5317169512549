import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Redirect } from "react-router";

import Login from "./screen/auth/Login";

import { AuthProvider } from "react-auth-kit";
import { RequireAuth } from "react-auth-kit";
import store from "../src/redux/stores/Store";

import Billing from "./screen/user/BillingList";
import Attendance from "./screen/user/Attendance";
import Contact from "./screen/user/Contact";
import Documents from "./screen/user/Documents";
import BillingList from "./screen/user/BillingList";
import Dashboard from "./screen/admin/Dashboard";
import ExerciseRoutine from "./screen/user/ExerciseRoutine";

import HeightWeight from "./screen/user/HeightWeight";
import Profile from "./screen/user/Profile/Profile";
import EditProfile from "./screen/user/Profile/EditProfile";
import ProfileEdit from "./screen/admin/ProfileEdit";
import AddFamilyMember from "./screen/user/Profile/AddFamilyMember";

function App() {
  return (
    <AuthProvider
      authType={"cookie"}
      authName={"_auth"}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}
    >
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Login />} />
          {/* <Route exact path="/login" element={<Login />} /> */}
          <Route
            exact
            path="/dashboard"
            element={
              <RequireAuth loginPath="/">
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/addfamilymember"
            element={
              <RequireAuth loginPath="/">
                <AddFamilyMember />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/heightweight/:id"
            element={
              <RequireAuth loginPath="/">
                <HeightWeight />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <RequireAuth loginPath="/">
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/editprofile"
            element={
              <RequireAuth loginPath="/">
                <EditProfile />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/profileedit/:id"
            element={
              <RequireAuth loginPath="/">
                <ProfileEdit />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/addfamilymember"
            element={
              <RequireAuth loginPath="/">
                <AddFamilyMember />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/billinglist"
            element={
              <RequireAuth loginPath="/">
                <BillingList />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/attendance"
            element={
              <RequireAuth loginPath="/">
                <Attendance />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/exerciseroutine"
            element={
              <RequireAuth loginPath="/">
                <ExerciseRoutine />
              </RequireAuth>
            }
          />
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;
