import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
function Sidebar() {
  const [activeMenu, setActiveMenu] = useState(false);
  const auth = useAuthUser();
  const location = useLocation();

  useEffect(() => {
    setActiveMenu(location.pathname);
  });
  return (
    <div className="sidebar">
      <div className="sidebar-wrapper">
        <div className="logo">
          <a
            href="#"
            onClick={(e) => e.preventDefault()}
            className="simple-text logo-normal"
          >
            MY GYM
            <h5 className="text-center">Welcome, {auth().name}</h5>
          </a>
        </div>
        <ul className="nav flex-column flex-nowrap overflow-hidden">
          <li className={`${activeMenu === "/dashboard" ? "active" : ""}`}>
            <Link to="/dashboard">
              <h2>
                <i className="fa fa-home"></i>
                <h5 className="list">Dashboard</h5>
              </h2>
            </Link>
          </li>
          <li className={`${activeMenu === "/profile" ? "active" : ""}`}>
            <Link to="/profile">
              <i class="fa fa-life-ring" aria-hidden="true"></i>
              <h5 className="list">Profile</h5>
            </Link>
          </li>
          <li className={`${activeMenu === "/billinglist" ? "active" : ""}`}>
            <Link to="/billinglist">
              <i class="fa fa-sticky-note" aria-hidden="true"></i>
              <h5 className="list">Billing</h5>
            </Link>
          </li>
          <li className={` ${activeMenu === "/attendance" ? "active" : ""}`}>
            <Link to="/attendance">
              <i class="fa fa-hand-paper" aria-hidden="true"></i>
              <h5 className="list">Attendance</h5>
            </Link>
          </li>
          <li
            className={` ${activeMenu === "/exerciseroutine" ? "active" : ""}`}
          >
            <Link to="/exerciseroutine">
              <i class="fa fa-table" aria-hidden="true"></i>
              <h5 className="list">Schedule</h5>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
