import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { Link } from "react-router-dom";
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  validation = () => {
    if (this.state.firstname == "") {
      alert("First name is required");
      return false;
    }
    if (this.state.lastname == "") {
      alert("Last name is required");
      return false;
    }
    if (this.state.email == "") {
      alert("Email is required");
      return false;
    }
    if (this.state.password == "") {
      alert("Password is required");
      return false;
    }
    if (this.state.confirmpassword == "") {
      alert("Confirm Password is required");
      return false;
    }
    if (this.state.phoneno == "") {
      alert("Phone No is required");
      return false;
    }

    return true;
  };
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  submitAddMember = () => {
    if (this.validation()) {
      alert("form submited");
    }
  };
  render() {
    const {
      tabpanel,
      firstname,
      lastname,
      email,
      password,
      confirmpassword,
      phoneno,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="row">
              <section className="about-area2 fix pb-padding pt-50 pb-80 content-area1">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <div className="circle text-center">
                          <p className="text">SJ</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="box text-center">
                    <button type="button" className="btn1 btn-success">
                      Active
                    </button>
                  </div>
                </div>
                <div className="btn2">
                  <h2 className="text-center">Samir Jana</h2>
                  <h5 className="text-center">Member since 06/19/2023</h5>
                </div>
                <div className="box text-center">
                  <button type="submit" className="btn btn-success">
                    <i className="tim-icons icon-pencil"> Edit</i>
                  </button>
                </div>
                <div className="btn3">
                  <h2 className="text-center">4585</h2>
                  <h5 className="text-center">Check-in code</h5>
                </div>
                <div className="btn5">
                  <h2 className="btn4">PERSONAL DETAILS</h2>
                </div>
                <div className="row">
                  <div className="form-group">
                    <h5 className="btn6">Gender</h5>
                  </div>
                  <div className="form-group">
                    <h5 className="btn7">Male</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <h5 className="btn6">Email Address</h5>
                  </div>
                  <div className="form-group">
                    <h5 className="btn8">Samir@gmail.com</h5>
                  </div>
                </div>
                <div className="btn5">
                  <h2 className="btn4">FAMILY ACCOUNT</h2>
                  <Link className="btn btn-success" to="/addfamilymember">
                    Add Family Member
                  </Link>
                </div>
              </section>

              <section className="about-area2 fix pb-padding pt-50 pb-80 content-area2">
                <h2 className="label1">Send Message</h2>

                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      className="form-control valid textboxstyle"
                      name="subject"
                      id="subject"
                      type="text"
                      placeholder="Subject"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <textarea
                      className="form-control valid textboxstyle  "
                      name="message"
                      id="message"
                      type="text"
                      placeholder="Enter Message Content..."
                    />
                  </div>
                </div>
                <div>
                  <Link
                    className="btn9 btn-success btn-lg float-right"
                    to="/invite"
                  >
                    Send Message
                  </Link>
                </div>
              </section>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Contact;
