import React, { Component } from "react";
import Sidebar from "../../layout/Sidebar";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../../helper/helpers";
import { ROOT_URL } from "../../../helper/vars";
import moment from "moment";
import { Link } from "react-router-dom";
import { Loader } from "../../../components/loader";

class AddFamilyMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      confirmpassword: "",
      phonenumber: "",
      aadharnumber: "",
      joindate: "",
      isVisibleLoader: false,
      city: "",
      state: "",
      country: "",
      selectedOption: "",
      selectedImage: "",
      relation: "",
      zipcode: "",
      address: "",
      dateofbirth: "",
      checkincode: "",
      height: "",
      weight: "",
    };
    this.onFileChange = this.onFileChange.bind(this);
  }
  onFileChange(e) {
    let files = e.target.files;
    let fileReader = new FileReader();
    console.log("file", JSON.stringify(files[0]));
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
      //this.setState({
      // selectedImage: event.target.result,
      //});
      this.updateState("selectedImage", event.target.result);
    };
  }
  validation = () => {
    if (this.state.firstname == "") {
      alert("First name is required");
      return false;
    }
    if (this.state.lastname == "") {
      alert("Last name is required");
      return false;
    }
    if (this.state.email == "") {
      alert("Email is required");
      return false;
    }
    if (this.state.phonenumber == "") {
      alert("Phone No is required");
      return false;
    }
    if (this.state.aadharnumber == "") {
      alert("Aadhar No is required");
      return false;
    }
    if (this.state.height == "") {
      alert("Height is required");
      return false;
    }
    if (this.state.weight == "") {
      alert("Weight is required");
      return false;
    }
    return true;
  };
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  submitAddMember = () => {
    if (this.validation()) {
      let postData = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        phonenumber: this.state.phonenumber,
        aadharnumber: this.state.aadharnumber,
        city: this.state.city,
        height: this.state.height,
        weight: this.state.weight,
        country: this.state.country,
        state: this.state.state,
        password: this.state.password,
        file: this.state.selectedImage,
        address: this.state.address,
        zipcode: this.state.zipcode,
        checkincode: this.state.checkincode,
        relation: this.state.relation,
        joindate: moment(this.state.joindate).format("YYYY-MM-DD"),
        dateofbirth: moment(this.state.dateofbirth).format("YYYY-MM-DD"),
      };
      //console.log("form data", postData);
      //return false;
      FetchPost("add-member", postData).then((result) => {
        console.log("response data : ", JSON.stringify(result));
        this.setState({ isVisibleLoader: false });
        if (result.status === 1) {
          window.location = ROOT_URL + "members/allMembers";
        }
      });
    }
  };
  render() {
    const {
      firstname,
      lastname,
      email,
      password,
      confirmpassword,
      phonenumber,
      aadharnumber,
      joindate,
      city,
      country,
      state,
      address,
      height,
      weight,
      relation,
      zipcode,
      dateofbirth,
      checkincode,
      isVisibleLoader,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Add Family Member</h2>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Link
                  className="btn btn-success btn-lg float-right"
                  to="/profile"
                >
                  Back
                </Link>
              </div>
            </div>
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
              <div className="container">
                {/* <form className="form"> */}
                <h2 className="textColor">Members Details</h2>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="firstname"
                        id="firstname"
                        type="text"
                        value={firstname}
                        placeholder="Enter Your First Name"
                        onChange={(e) => {
                          this.updateState("firstname", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="lastname"
                        id="lastname"
                        type="lastname"
                        value={lastname}
                        placeholder="Enter Your Last Name"
                        onChange={(e) => {
                          this.updateState("lastname", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <select
                        className="form-control valid textboxstyle"
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                      >
                        <option value="">Gender</option>
                        <option value="option1">Male</option>
                        <option value="option2">Female</option>
                        <option value="option3">Other</option>
                      </select>
                      <p>Gender: {this.state.selectedOption}</p>
                      {/* <input
                        className="form-control valid textboxstyle"
                        name="gender"
                        id="gender"
                        type="gender"
                        placeholder="Gender"
                      /> */}
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="weight"
                        id="weight"
                        type="text"
                        value={weight}
                        placeholder="Enter Your Weight"
                        onChange={(e) => {
                          this.updateState("weight", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="height"
                        id="height"
                        type="height"
                        value={height}
                        placeholder="Enter Your Height"
                        onChange={(e) => {
                          this.updateState("height", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="phonenumber"
                        id="phonenumber"
                        type="number"
                        value={phonenumber}
                        placeholder="Enter Your Phone Number"
                        onChange={(e) => {
                          this.updateState("phonenumber", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="email"
                        id="email"
                        type="email"
                        value={email}
                        placeholder="Enter Your Email"
                        onChange={(e) => {
                          this.updateState("email", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="aadharnumber"
                        id="aadharnumber"
                        type="number"
                        value={aadharnumber}
                        placeholder="Enter Your Aadhar Number"
                        onChange={(e) => {
                          this.updateState("aadharnumber", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="dateofbirth"
                        id="dateofbirth"
                        type="text"
                        value={dateofbirth}
                        placeholder="Date Of Birth"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                        onChange={(e) => {
                          this.updateState("dateofbirth", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="checkincode"
                        id="checkincode"
                        type="text"
                        value={checkincode}
                        placeholder="Pick A Check-in Code"
                        onChange={(e) => {
                          this.updateState("checkincode", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="joindate"
                        id="joindate"
                        type="text"
                        value={joindate}
                        placeholder="Join Date"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                        onChange={(e) => {
                          this.updateState("joindate", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="textColor">Address</h2>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          name="address"
                          id="address"
                          type="text"
                          placeholder="Street Address"
                          value={address}
                          onChange={(e) => {
                            this.updateState("address", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control valid textboxstyle"
                          name="city"
                          id="city"
                          type="text"
                          placeholder="City"
                          value={city}
                          onChange={(e) => {
                            this.updateState("city", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <input
                          className="form-control valid textboxstyle"
                          name="zipcode"
                          id="zipcode"
                          type="number"
                          value={zipcode}
                          placeholder="ZIP/Postal Code"
                          onChange={(e) => {
                            this.updateState("zipcode", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="form-group">
                        <input
                          className="form-control valid textboxstyle"
                          name="state"
                          id="state"
                          type="text"
                          placeholder="State"
                          value={state}
                          onChange={(e) => {
                            this.updateState("state", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <input
                          className="form-control valid textboxstyle"
                          name="country"
                          id="country"
                          type="text"
                          placeholder="Country"
                          value={country}
                          onChange={(e) => {
                            this.updateState("country", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="textColor">Emergency Contacts</h2>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          name="contactname"
                          id="contactname"
                          type="text"
                          placeholder="Contact Name"
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control valid textboxstyle"
                          name="phonenumber"
                          id="phonenumber"
                          type="number"
                          value={phonenumber}
                          placeholder="Phone Number"
                          onChange={(e) => {
                            this.updateState("phonenumber", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control valid textboxstyle"
                          name="relation"
                          id="relation"
                          type="text"
                          value={relation}
                          placeholder="Relation"
                          onChange={(e) => {
                            this.updateState("relation", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="textColor">Photo</h2>
                  <div className="row">
                    <div className="col-sm-3">
                      <input
                        type="file"
                        className="form-control textboxstyle"
                        onChange={this.onFileChange}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="textColor">Account Password</h2>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          name="password"
                          id="password"
                          type="password"
                          value={password}
                          placeholder="Password"
                          onChange={(e) => {
                            this.updateState("password", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control valid textboxstyle"
                          name="confirmpassword"
                          id="confirmpassword"
                          type="password"
                          value={confirmpassword}
                          placeholder="Confirm Password"
                          onChange={(e) => {
                            this.updateState("confirmpassword", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <button
                    type="submit"
                    className="btn btn-success btn-lg float-right"
                    onClick={() => {
                      this.submitAddMember();
                    }}
                  >
                    Complete Sign-Up
                  </button>
                </div>
              </div>
            </section>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default AddFamilyMember;
