import React, { useState, useEffect } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Sidebar from "../layout/Sidebar";
import { Link } from "react-router-dom";
import { FetchPost } from "../../helper/helpers";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";

function Dashboard() {
  const navigate = useNavigate();
  const auth = useAuthUser();
  // const [heightweightenquiry, setHeightWeight] = useState(0);

  useEffect(() => {}, [0]);

  return (
    <div className="wrapper">
      <div className="navbar-minimize-fixed">
        <button className="minimize-sidebar btn btn-link btn-just-icon">
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
        </button>
      </div>
      <Sidebar></Sidebar>
      <div className="main-panel">
        <Header></Header>
        <div
          className="modal modal-search fade"
          id="searchModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="searchModal"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputGroup"
                  placeholder="SEARCH"
                />
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="tim-icons icon-simple-remove"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="card card-stats">
                <Link to={`/heightweight/${auth().id}`}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-7">
                        <div className="info-icon icon-warning">
                          <i className="tim-icons icon-chart-bar-32"></i>
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="numbers">
                          <p className="card-category">
                            <Link
                              to={`/heightweight/${auth().id}`}
                              className=""
                            >
                              Height & Weight
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Dashboard;
