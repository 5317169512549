import React, { Component } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import Sidebar from "../../layout/Sidebar";
import { Button } from "bootstrap";
import { Link } from "react-router-dom";
import { FetchPost } from "../../../helper/helpers";
import { withAuthUser, useAuthUser } from "react-auth-kit";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from "recharts";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member_id: "",
      isCheckedIn: false,
      attendance_id: "",
      checkin_time: "",
      checkout_time: "",
      isVisibleLoader: true,
      selectedMonth: "",
      selectedYear: "",
      attendanceList: [],
      monthlydata: [],
      year: "",
      attendancechartData: [],
    };
  }
  componentDidMount() {
    this.setState({ member_id: this.props.authState.id }, () => {
      this.getMember();
      this.getAllAttendance();
    });
  }
  getAllAttendance = () => {
    let postData = {
      member_id: this.state.member_id,
      month: this.state.selectedMonth,
      year: this.state.selectedYear,
    };
    FetchPost("attendance-report", postData).then((result) => {
      console.log("attendance response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          attendanceListList: result.revinews,
          attendancechartData: result.attendancechartData,
        });
      }
    });
  };
  updateState = (stateVar, stateVal) => {
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ year: event.target.value }, () => {});
  };
  handlemonthChange = (event) => {
    this.setState({ selectedMonth: event.target.value }, () => {
      this.getAllAttendance();
    });
  };
  handleyearChange = (event) => {
    this.setState({ selectedYear: event.target.value }, () => {
      this.getAllAttendance();
    });
  };
  getMember = () => {
    let postData = { fk_user_id: this.state.member_id };
    FetchPost("get-user-by-id", postData).then((result) => {
      console.log("member data : ", JSON.stringify(result.data));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          firstname: result.data.firstname,
          lastname: result.data.lastname,
          email: result.data.email,
          phonenumber: result.data.phonenumber,
          aadharnumber: result.data.aadharnumber,
          joindate: result.data.joindate,
          dateofbirth: result.data.dateofbirth,
          city: result.data.city,
          height: result.data.height,
          weight: result.data.weight,
          state: result.data.state,
          country: result.data.country,
          password: result.data.password,
          address: result.data.address,
          zipcode: result.data.zipcode,
          checkincode: result.data.checkincode,
          relation: result.data.relation,
        });
        // console.log("post data is..", postData);
        // return false;
      }
    });
  };

  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  submitAddMember = () => {
    if (this.validation()) {
      alert("form submited");
    }
  };
  render() {
    const {
      firstname,
      lastname,
      dateofbirth,
      address,
      phonenumber,
      email,
      city,
      state,
      country,
      zipcode,
      monthlydata,
      attendancechartData,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="row">
              <section className="about-area2 fix pb-padding pt-50 pb-80 content-area1">
                <div className="container"></div>

                {/* <div className="box text-center">
                    <button type="button" className="btn1 btn-success">
                      Active
                    </button>
                  </div> */}

                <div className="btn2">
                  <h2 className="text-center">
                    {firstname} {lastname}
                  </h2>
                  <Link to="/editprofile">
                    <button type="button" className="btn btn-success">
                      <i className="tim-icons icon-pencil"></i>Edit
                    </button>
                  </Link>
                </div>
                <p className="text5">PERSONAL DETAILS</p>
                <div className="row">
                  <div className="col-sm-4">
                    <h5 className="text3">Date Of Birth</h5>
                  </div>
                  <div>
                    <h5 className="text4">{dateofbirth}</h5>
                  </div>
                </div>
                <hr
                  style={{
                    background: "gray",
                    color: "gray",
                    borderColor: "gray",
                    height: "2px",
                    width: "100%",
                  }}
                />
                <div className="row">
                  <div className="col-sm-4">
                    <h5 className="text3">Phone Number</h5>
                  </div>
                  <div>
                    <h5 className="text4">{phonenumber}</h5>
                  </div>
                </div>
                <hr
                  style={{
                    background: "gray",
                    color: "gray",
                    borderColor: "gray",
                    height: "2px",
                    width: "100%",
                  }}
                />
                <div className="row">
                  <div className="col-sm-4">
                    <h5 className="text3">Email</h5>
                  </div>
                  <div>
                    <h5 className="text4">{email}</h5>
                  </div>
                </div>
                <hr
                  style={{
                    background: "gray",
                    color: "gray",
                    borderColor: "gray",
                    height: "2px",
                    width: "100%",
                  }}
                />
                <div className="row">
                  <div className="col-sm-4">
                    <h5 className="text3">Address</h5>
                  </div>
                  <div>
                    <h5 className="text4">
                      {address}
                      <br />
                      {city},{state},{zipcode},{country}
                    </h5>
                  </div>
                </div>
                <hr
                  style={{
                    background: "gray",
                    color: "gray",
                    borderColor: "gray",
                    height: "2px",
                    width: "100%",
                  }}
                />
                <p className="text5">FAMILY ACCOUNT</p>
                <Link to="/addfamilymember">
                  <button type="button" className="btn btn-success">
                    Add Family Member
                  </button>
                </Link>
              </section>

              <section className="about-area2 fix pb-padding pt-50 pb-80 content-area2">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="form-group">
                      <select
                        className="form-control valid textboxstyle"
                        value={this.state.selectedMonth}
                        onChange={this.handlemonthChange}
                      >
                        <option value="">--Month--</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">Augest</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <select
                        className="form-control valid textboxstyle"
                        value={this.state.selectedYear}
                        onChange={this.handleyearChange}
                      >
                        <option value="">--Year--</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                      </select>
                    </div>
                  </div>
                </div>
                <ResponsiveContainer width="100%" aspect={3}>
                  <BarChart
                    width={800}
                    height={400}
                    data={attendancechartData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="date"
                      type="category"
                      allowDuplicatedCategory={false}
                    >
                      <Label value=" " position="insideBottom" />
                    </XAxis>
                    <XAxis
                      dataKey="hour"
                      tick={{ angle: -45 }}
                      height={60}
                      interval={0}
                    />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="value" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </section>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default withAuthUser(Profile);
