import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { Button } from "bootstrap";
import { withAuthUser } from "react-auth-kit";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";
import { FetchPost } from "../../helper/helpers";

class HeightWeight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleLoader: false,
      disabled1: true,
      disabled2: true,
      disabled3: true,
      // profile_id: "",
      height: "",
      weight: "",
      member_id: "",
    };
  }
  handleButtonClickName = () => {
    this.setState((prevState) => ({
      disabled1: !prevState.disabled1,
    }));
  };
  handleButtonClickEmail = () => {
    this.setState((prevState) => ({
      disabled2: !prevState.disabled2,
    }));
  };
  handleButtonClickImage = () => {
    this.setState((prevState) => ({
      disabled3: !prevState.disabled3,
    }));
  };

  componentDidMount() {
    const member_id = window.location.href.split("/")[4];
    console.log("update member id ", member_id);
    this.setState({ member_id: member_id }, () => {
      this.getHeightWeightdata();
    });
  }
  getHeightWeightdata = () => {
    let postData = { member_id: this.state.member_id };
    console.log("form data", postData);
    FetchPost("list-height-weight", postData).then((result) => {
      console.log("member height weight data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          height: result.data.height,
          weight: result.data.weight,
        });
      }
    });
  };

  updateState = (stateVar, stateVal) => {
    this.setState({
      [stateVar]: stateVal,
    });
  };
  updateHeightWeight = () => {
    let postData = {
      member_id: this.state.member_id,
      height: this.state.height,
      weight: this.state.weight,
    };
    console.log("form data", postData);
    FetchPost("update-height-weight", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      this.getHeightWeightdata();
    });
  };

  render() {
    const {
      isVisibleLoader,
      disabled1,
      disabled2,
      disabled3,
      height,
      weight,
      photo,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Height & Weight</h2>
            </div>
            <div className="tab-content" id="myTabContent">
              <div className="row">
                <div className="col-md-12">
                  <Link
                    className="btn btn-success btn-lg float-right"
                    to="/dashboard"
                  >
                    Back
                  </Link>
                </div>
              </div>
              <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                <div className="container">
                  <div className=" "></div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle2"
                          name="height"
                          id="height"
                          type="text"
                          disabled={disabled1}
                          // placeholder={this.props.authState.name}
                          onChange={(e) => {
                            this.updateState("height", e.target.value);
                          }}
                          value={height}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <Link
                        to=""
                        onClick={this.handleButtonClickName}
                        className="btn btn-link btn-warning btn-icon btn-sm edit"
                      >
                        <i
                          className="tim-icons icon-pencil"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <input
                          className="form-control valid textboxstyle2"
                          name="weight"
                          id="weight"
                          type="text"
                          value={weight}
                          disabled={disabled2}
                          // placeholder={this.props.authState.email}
                          onChange={(e) => {
                            this.updateState("weight", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <Link
                        to=""
                        onClick={this.handleButtonClickEmail}
                        className="btn btn-link btn-warning btn-icon btn-sm edit"
                      >
                        <i
                          className="tim-icons icon-pencil"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                  </div>
                  <div>
                    <div className="box text-center">
                      <button
                        type="submit"
                        className="btn btn-success"
                        onClick={() => {
                          this.updateHeightWeight();
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default HeightWeight;
